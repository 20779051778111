import React from 'react';
import classNames from 'classnames';
import { ReactNode, useContext, useEffect, useMemo } from 'react';

import { ViewContext } from 'shared/components/data-table/context/view.context';
import { ActionsContext } from 'shared/components/data-table/context/actions.context';
import { DataViewFormat } from 'shared/enums/data-view.enum';
import { ItemsContext } from 'shared/components/data-table/context/items.context';

interface OwnProps<T = {}> {
  renderCard?: (item: T, index: number) => JSX.Element;
  selectAll?: JSX.Element; // currently only in tenders. Would love for select all to be part of this or a shard component
  children?: ReactNode;
}

export const CardView: React.FC<OwnProps> = ({children, renderCard, selectAll}) => {
  const {format} = useContext(ViewContext);
  const {registerCardView} = useContext(ActionsContext);
  const items = useContext(ItemsContext);

  useEffect(registerCardView, []);

  const classes = useMemo(() => classNames('card-view-component card-view', {'grid-view-hidden': format !== DataViewFormat.Card}), [format]);

  return (
    <div className={classes} data-testid="card-view-component">
      {selectAll ? selectAll : null}
      {items?.length && renderCard ? items.map(renderCard) : children}
    </div>
  );
};
