import React from 'react';
import classNames from 'classnames';
import { useCallback, useContext, useMemo } from 'react';

import { Translation } from 'shared/components/translation/translation.component';
import { SortOrder } from 'shared/enums/sort-order.enum';
import { ActionsContext } from 'shared/components/data-table/context/actions.context';
import { SortContext } from 'shared/components/data-table/context/sort.context';

interface Props {
  sortKey: string;
  resource?: ResourceKey;

  [key: string]: any;
}

export const SortHeader: React.FC<Props> = ({sortKey, children, resource, ...props}) => {
  const actions = useContext(ActionsContext);
  const {sortByColumnName, sortOrder} = useContext(SortContext);

  const onSort = useCallback(() => actions.sortByColumn(sortKey), [sortKey, actions]);
  const classes = useMemo(() => classNames('btn-link', 'sort', {
    'sort-asc': sortByColumnName === sortKey && sortOrder === SortOrder.ASC,
    'sort-desc': sortByColumnName === sortKey && sortOrder !== SortOrder.ASC
  }), [sortKey, sortOrder, sortByColumnName]);

  const ariaSort = useMemo(() =>
      (sortByColumnName === sortKey && sortOrder === SortOrder.ASC)
        ? 'ascending'
        : (
          (sortByColumnName === sortKey && sortOrder !== SortOrder.ASC)
            ? 'descending'
            : 'none'
        ),
    [sortByColumnName, sortKey, sortOrder]
  );

  return (
    <th {...props}
        scope="col"
        role="columnheader"
        aria-sort={ariaSort}
    >
      <button onClick={onSort} className={classes}>
        {resource ? <Translation resource={resource}/> : children}
      </button>
    </th>
  );
};
