import { createContext } from 'react';

export interface ActionsContextType  {
  registerCardView: () => void;
  registerGridView: () => void;
  registerPaginator: (paginator: (pageNumber?: number, pageSize?: number) => any) => void;
  sortByColumn: (column: string) => void;
}

export const ActionsContext = createContext<ActionsContextType>({
  registerCardView: null,
  registerGridView: null,
  registerPaginator: null,
  sortByColumn: null,
});
