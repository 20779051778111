import { useMemo } from 'react';
import { DataViewFormat } from 'shared/enums/data-view.enum';

export const useEffectiveViewFormat = (viewFormat: DataViewFormat, hasGridView: boolean, hasCardView: boolean) =>
  useMemo(() => {
    if (!hasCardView) {
      return DataViewFormat.Grid;
    } else if (!hasGridView) {
      return DataViewFormat.Card;
    } else {
      return viewFormat;
    }
  }, [hasCardView, hasGridView, viewFormat]);
