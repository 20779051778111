import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Cookie } from 'ng2-cookies';
import { DataViewFormat } from 'shared/enums/data-view.enum';
import { Container } from 'typedi';

export const VIEW_FORMAT_COOKIE_NAME = `${Container.get('appConstants.appName')}.data-view-format`;

export type UseViewFormatHookResult = [DataViewFormat, (e: ChangeEvent<HTMLInputElement>) => void];

export const useViewFormat = (onViewChange, override?): [DataViewFormat, (e: ChangeEvent<HTMLInputElement>) => void] => {
  const [viewFormat, setViewFormat] = useState<DataViewFormat>(Number(Cookie.get(VIEW_FORMAT_COOKIE_NAME)) || DataViewFormat.Grid);

  const setView = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const view: DataViewFormat = Number(e.target.value);
    Cookie.set(VIEW_FORMAT_COOKIE_NAME, view.toString());
    setViewFormat(view);
    if (onViewChange) {
      onViewChange(view);
    }
  }, [setViewFormat, onViewChange]);

  useEffect(() => {
    if (override !== undefined) {
      setViewFormat(override);
    }
  }, [override]);

  return [viewFormat, setView];
};
