import React from 'react';
import classNames from 'classnames';

import { ErrorBoundary } from 'shared/components/error-boundary/error-boundary.component';

import './card.component.scss';

export interface Props {
  className?: string;
  identifier?: string;
  [key: string]: any;
}

export const Card: React.FC<Props> = ({children, identifier, className, ...props}) =>
  <div id={identifier} className={classNames('ns-card shadow standard-card', className)} {...props}>
    <ErrorBoundary className="space-outer-top-xl space-outer-bottom-xl space-outer-left-lg space-outer-right-lg">
      {children}
    </ErrorBoundary>
  </div>;
