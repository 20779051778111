import React from 'react';
import classNames from 'classnames';
import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ErrorBoundary, SectionLevelError } from 'shared/components/error-boundary/error-boundary.component';
import { ViewContext } from 'shared/components/data-table/context/view.context';
import { DataViewFormat } from 'shared/enums/data-view.enum';
import { ActionsContext } from 'shared/components/data-table/context/actions.context';
import { ItemsContext } from 'shared/components/data-table/context/items.context';
import { ColumnCountContext } from 'shared/components/data-table/context/column-count.context';

import './grid-view.component.scss';

interface OwnProps<T = {}> {
  className?: string;
  tableClassName?: string;
  scrollable?: boolean;
  headerRow: JSX.Element;
  renderBodyRow?: (item: T, colSpan: number, index: number) => JSX.Element;
  children?: ReactNode | any;
}

export const GridView: React.FC<OwnProps> = ({children, className, tableClassName, headerRow, renderBodyRow, scrollable}) => {
  const {format, hasCard} = useContext(ViewContext);
  const {registerGridView} = useContext(ActionsContext);
  const items = useContext(ItemsContext);

  useEffect(registerGridView, []);

  const [columnCount, setColumnCount] = useState(6);

  useEffect(() => {
    if (headerRow) {
      const headerClone = React.cloneElement(headerRow);
      const headerCells = headerClone.props.children || headerClone.type(headerClone.props)?.props?.children;

      setColumnCount(React.Children.count((headerCells as any[]).filter(Boolean)));
    }
  }, [headerRow]);

  const classes = useMemo(() =>
      classNames(className, 'grid-view-component', 'data-table-view', 'grid-view', {
        'results-scrollable': scrollable,
        'hidden': format !== DataViewFormat.Grid,
        'grid-view-with-card': hasCard
      }),
    [format, hasCard, scrollable]
  );

  const renderError = useCallback(() =>
      <tr>
        <td colSpan={columnCount}>
          <SectionLevelError/>
        </td>
      </tr>,
    [columnCount]
  );

  return (
    <ColumnCountContext.Provider value={columnCount}>
      <div className={classes}>
        <table className={classNames('data-table', tableClassName)}>
          <thead>
          {headerRow}
          </thead>
          <tbody>
          <ErrorBoundary render={renderError}>
            {items?.length && renderBodyRow
              ? items.map((item, index) => renderBodyRow(item, columnCount, index))
              : children
            }
          </ErrorBoundary>
          </tbody>
        </table>
      </div>
    </ColumnCountContext.Provider>
  );
};
