import { Dispatch, SetStateAction, useCallback, useState } from 'react';

export interface OnSortCallback {
  (sortByColumnName: string, sortOrder: string): any;
}

interface UseSortingHook {
  (initialSorting: SortableRequest): [SortableRequest, OnSortCallback, Dispatch<SetStateAction<SortableRequest>>];
}

export const useSorting: UseSortingHook = initialSorting => {
  const [sorting, setSorting] = useState<SortableRequest>(initialSorting);
  const onSort = useCallback((sortByColumnName, sortOrder) => setSorting({sortByColumnName, sortOrder}), []);

  return [sorting, onSort, setSorting];
};

export const usePagination = (initialPagination: PageableRequest): [PageableRequest, Dispatch<SetStateAction<PageableRequest>>] => {
  return useState<PageableRequest>(initialPagination);
};

interface UseSortingAndPaginationHook {
  (defaultSort: SortableRequest, defaultPagination: PageableRequest):
    [SortableRequest, OnSortCallback, PageableRequest, Dispatch<SetStateAction<PageableRequest>>];
}

export const useSortingAndPagination: UseSortingAndPaginationHook = (defaultSort, defaultPagination) => {
  const [sorting, onSort] = useSorting(defaultSort);
  const [pagination, onPagination] = usePagination(defaultPagination);
  return [sorting, onSort, pagination, onPagination];
};
