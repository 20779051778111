import { useCallback, useRef } from 'react';

import { useForceUpdate } from 'app/hooks/use-force-update.hook';

type PaginatorCallback = (pageNumber?: number, pageSize?: number) => any;

export const useRegisteredPaginator = (): [PaginatorCallback, (paginator: PaginatorCallback) => any] => {
  const forceRefresh = useForceUpdate();
  const paginatorRef = useRef<PaginatorCallback>(null);

  const registerPaginator = useCallback((paginator: PaginatorCallback) => {
    if (paginatorRef.current !== paginator) {
      paginatorRef.current = paginator;
      forceRefresh();
    }
  }, [paginatorRef]);

  return [paginatorRef.current, registerPaginator];
};
