import { ChangeEvent, useCallback } from 'react';

import { SortOrder } from 'shared/enums/sort-order.enum';

interface UseSortingCallbacksHook {
  (
    sortConfig: SortableRequest,
    onSort: (sortByColumnName: string, sortOrder: SortOrder) => any
  ): [
    () => void,
    (event: ChangeEvent<HTMLSelectElement>) => void,
    (column: string) => void
  ]
}

export const useSortingCallbacks: UseSortingCallbacksHook = ({sortOrder, sortByColumnName}, onSort) => {
  const emitSortParams = useCallback((sc: string, so: SortOrder) => onSort && onSort(sc, so), [onSort]);

  const updateSortOrder = useCallback(() => {
    // this is used by the Card View Sorting drop-down menus
    const value = sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
    emitSortParams(sortByColumnName, value);
  }, [emitSortParams, sortOrder, sortByColumnName]);

  const sortByEvent = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
    // this is used by the Card View Sorting drop-down menus
    const value = event.target.value;

    if (value === '') {
      return; // selected blank entry, no sorting
    }

    emitSortParams(value, sortOrder);
  }, [emitSortParams, sortOrder]);

  const sortByColumn = useCallback((column: string) => {
    let newOrder;
    if (column === sortByColumnName) {
      // resorting the same column
      newOrder = sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
    } else {
      // sorting by a different column
      newOrder = SortOrder.ASC;
    }

    emitSortParams(column, newOrder);
  }, [sortByColumnName, sortOrder, emitSortParams]);

  return [updateSortOrder, sortByEvent, sortByColumn];
};
