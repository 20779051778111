import { useMemo } from 'react';

export const useDefaultActionsContext = (registerCardView, registerGridView, registerPaginator, sortByColumn) => {
  return useMemo(() => {
    return  {
      registerCardView,
      registerGridView,
      registerPaginator,
      sortByColumn,
    };
  }, [registerCardView, registerGridView, registerPaginator, sortByColumn])
}
