import React from 'react';
import classNames from 'classnames';
import { ChangeEvent } from 'react';

import { Label } from 'shared/components/elements/elements.components';

interface Props {
  className?: string;
  pageSize: number;
  paginate: (event: ChangeEvent<HTMLSelectElement>) => void;
  pageSizeResource?: ResourceKey;
}

export const PageSize: React.FC<Props> = ({className, pageSize, paginate, pageSizeResource}) =>
  <div className={classNames(className, 'page-size no-wrap')}>
    <Label className="space-outer-right-xs" htmlFor="page-size" resource={pageSizeResource ?? 'RESULTS_PER_PAGE'} />
    <select
      id="page-size"
      onChange={paginate}
      value={pageSize}
    >
      <option value="10">10</option>
      <option value="25">25</option>
      <option value="50">50</option>
      <option value="100">100</option>
    </select>
  </div>;
