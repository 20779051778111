import { createContext } from 'react';

import { DataViewFormat } from 'shared/enums/data-view.enum';

export interface ViewContextType {
  format: DataViewFormat;
  hasGrid: boolean;
  hasCard: boolean;
}

export const ViewContext = createContext<ViewContextType>({
  format: DataViewFormat.Grid,
  hasGrid: false,
  hasCard: false,
});
