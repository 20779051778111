import React from 'react';
import { useContext } from 'react';

import { ColumnCountContext } from 'shared/components/data-table/context/column-count.context';
import { ErrorBoundary, SectionLevelError } from 'shared/components/error-boundary/error-boundary.component';

import './row.component.scss';

export interface Props {
  columns?: number;
  identifier?: string;

  [key: string]: any;
}

export const Row: React.FC<Props> = ({children, columns, identifier, ...props}) => {
  const columnCount = useContext(ColumnCountContext);
  return (
    <ErrorBoundary render={() =>
      <tr>
        <td colSpan={columns || columnCount}>
          <SectionLevelError/>
        </td>
      </tr>
    }>
      <tr {...props}>{children}</tr>
    </ErrorBoundary>
  );
};
