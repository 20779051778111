import React from 'react';
import { ReactNode, useMemo } from 'react';

import { DataViewFormat } from 'shared/enums/data-view.enum';
import { ViewContext } from 'shared/components/data-table/context/view.context';
import { SortContext } from 'shared/components/data-table/context/sort.context';
import { ActionsContext } from 'shared/components/data-table/context/actions.context';
import { ItemsContext } from 'shared/components/data-table/context/items.context';

export interface DataTableContext<T = {}> {
  format: DataViewFormat;
  hasGrid: boolean;
  hasCard: boolean;
  actions: {
    registerCardView: () => void;
    registerGridView: () => void;
    registerPaginator: (paginator: (pageNumber?: number, pageSize?: number) => any) => void;
    sortByColumn: (column: string) => void
  };
  sortConfig: SortableRequest;
  items: T[];
}

interface Props {
  value: DataTableContext;
  children: ReactNode;
}

export const DataTableContextProvider: React.FC<Props> = ({value: {format, hasCard, hasGrid, items, actions, sortConfig}, children}) => {
  const viewContext = useMemo(() => ({format, hasCard, hasGrid}), [format, hasCard, hasGrid]);
  return (
    <ViewContext.Provider value={viewContext}>
      <ActionsContext.Provider value={actions}>
        <SortContext.Provider value={sortConfig}>
          <ItemsContext.Provider value={items}>
            {children}
          </ItemsContext.Provider>
        </SortContext.Provider>
      </ActionsContext.Provider>
    </ViewContext.Provider>
  );
};
