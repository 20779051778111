import { useMemo } from 'react';

export const useRenderHeaderProps = (sortByColumnName, sortOrder, cardSortOptions, pagination, sortConfig, totalRecords, updateSortOrder, sortByEvent, paginate) => {
  return useMemo(() => {
    return {
      updateSortOrder,
      onSort: sortByEvent,
      onPagination: paginate,
      sortByColumnName,
      sortOrder,
      cardSortOptions,
      pagination,
      sortConfig,
      totalRecords,
    };
  }, [sortByColumnName, sortOrder, cardSortOptions, pagination, sortConfig, totalRecords, updateSortOrder, sortByEvent, paginate])
};
