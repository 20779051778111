import React from 'react';

import { ErrorBoundary } from 'shared/components/error-boundary/error-boundary.component';
import { EM_DASH } from 'app/globals/constants';

import './cell.component.scss';

export interface Props {
  fallback?: string;
  [key: string]: any;
}

export const Cell: React.FC<Props> = ({children, fallback = EM_DASH, ...props}) =>
  <td {...props}>
    <ErrorBoundary errorType="DATA_LEVEL_ERROR">
      {children !== null && children !== undefined && children !== '' ? children : fallback}
    </ErrorBoundary>
  </td>;
